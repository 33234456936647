<template>
  <div class="animated fadeIn">
    <b-row>
      <b-col sm="3" lg="3">
        <b-card no-body class="bg-danger" data-page="add-analysis-result" @click="formaGit($event)">
          <b-card-body class="pb-0">
            <h6 class="mb-3">Add Analysis Result</h6>
            <p v-if="false">Add Analysis Result</p>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { headers, User } from '@/shared/http';
import { forms } from '@/shared/forms';

export default {
  name: 'anasayfa',
  components: {
  },
  data() {
    return {
    }
  },
  mounted() {
  },
  methods: {
    
    formaGit(data) {
      const page = data.currentTarget.dataset.page
      
      this.$router.push({ path: page })
    }
  }
  
}
</script>

<style>
  /* IE fix */
  #card-chart-01, #card-chart-02 {
    width: 100% !important;
  }

  .card {
    cursor: pointer;
    color: #fff;
    margin-bottom: 5px !important;
  }
</style>
